import React from "react";
import BookTable from "../components/BookTable";

export default class BookPurchasePage extends React.PureComponent<RouterPropsType> {
  render() {
    return <BookTable
      type="purchaseReturn"
      history={this.props.history}
    />
  }
}